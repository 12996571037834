


































































































































































import KButtonAction from '@/@core/components/button/KButtonAction.vue';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import KArrayInput from '@/@core/components/input/KArrayInput.vue';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KCurrencyInput from '@/@core/components/input/k-currency-input.vue';
import KFileInput from '@/@core/components/input/KFileInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  currencyToNumber,
  useNumberToCurrency,
  toCurrency
} from '@/@core/helpers/formatter';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import ProductDetail from '@/modules/product/ui/ProductDetail.vue';
import ProductPhoto from '@/modules/product/ui/ProductPhoto.vue';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import { useProductStore } from '../../product/services/store';
import { IndustryProductForm, Product } from '../types';
import { useMarketingAreaStore } from '@/modules/marketing-area/services/store';

export default defineComponent({
  components: {
    KCard,
    KText,
    KButtonAction,
    KDialog,
    KTextInput,
    KArrayInput,
    KComboboxInput,
    KFileInput,
    ProductPhoto,
    ProductDetail,
    KCurrencyInput
  },
  name: 'IndustryProductForm',
  props: {
    data: {
      type: Object as PropType<IndustryProductForm>,
      required: true
    },
    id: {
      type: String
    }
  },
  emits: ['refresh:data'],
  setup(props, { emit }) {
    const store = useProductStore();
    const marketingAreaStore = useMarketingAreaStore();
    const headers = [
      {
        value: 'name',
        text: 'Nama Produk'
      },
      {
        value: 'capacityPerMonth',
        text: 'Kapasitas per Bulan',
        align: 'right'
      },
      {
        value: 'pricePerUnit',
        text: 'Harga Satuan',
        align: 'right'
      },
      {
        value: 'productionValue',
        text: 'Nilai Produksi / tahun',
        align: 'right'
      },
      {
        value: 'marketingArea.name',
        text: 'Pemasaran'
      },
      {
        value: 'photos',
        text: 'Foto'
      },
      {
        value: 'actions',
        text: '',
        width: '1%',
        sortable: false
      }
    ];
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const showFormDialog = ref(false);
    const showDeleteDialog = ref(false);
    const showDetailDialog = ref(false);
    const formData: Ref<Product & {id: string | undefined}> = ref({
      id: undefined,
      name: '',
      capacityPerMonth: undefined,
      capacityPerYear: undefined,
      pricePerUnit: undefined,
      productionValue: undefined,
      marketingArea: '',
      photos: []
    });

    const updateProductionPerYear = (item: any) => {
      item.capacityPerYear = (item.capacityPerMonth || 0) * 12;
      reRenderCount.value++;
    };

    const updateProductionValue = (item: any) => {
      item.productionValue = toCurrency(
        currencyToNumber(item.capacityPerYear) *
          currencyToNumber(item.pricePerUnit)
      );
      reRenderCount.value++;
    };

    const isEdit = computed(() => !!formData.value.id)
    const clickEdit = (product: Product & {id: string | undefined}) => {
      formData.value = product
      formData.value.photos = []
      showFormDialog.value = true
    }

    const onSubmit = async () => {
      const data: any = { ...formData.value };
      const hasMarketingArea = data?.marketingArea
      if (hasMarketingArea) {
        const marketingAreaObject: any = marketingAreaStore.marketingAreas.find((m: any) => m.name == data.marketingArea)
        data.marketingArea = marketingAreaObject.id
      }
      const industryId = (props as any).data?.id;
      data.industry = industryId;

      isLoading.value = true;

      const result = await store.postProduct(data);
      if (result) {
        useUiStateStore().createSnackbar({
          message: `Produk ${formData.value.name} berhasil ditambahkan.`
        });
        showFormDialog.value = false;
        formData.value = {
          id: undefined,
          name: '',
          capacityPerMonth: undefined,
          capacityPerYear: undefined,
          pricePerUnit: undefined,
          productionValue: undefined,
          marketingArea: '',
          photos: []
        };
        emit('refresh:data');
      }

      isLoading.value = false;
    };

    const onDelete = async () => {
      const data: any = { ...formData.value };

      isLoading.value = true;

      const result = await store.deleteProduct(data);
      if (result) {
        useUiStateStore().createSnackbar({
          message: `Produk ${formData.value.name} berhasil dihapus.`
        });
        showDeleteDialog.value = false;
        formData.value = {
          id: undefined,
          name: '',
          capacityPerMonth: undefined,
          capacityPerYear: undefined,
          pricePerUnit: undefined,
          productionValue: undefined,
          marketingArea: '',
          photos: []
        };
        emit('refresh:data');
      }

      isLoading.value = false;
    };

    const clickDelete = (data: any) => {
      formData.value = { ...data };
      showDeleteDialog.value = true;
    };

    const clickDetail = (data: any) => {
      formData.value = { ...data };
      showDetailDialog.value = true;
    };

    const resetFormData = () => {
      formData.value = {
        id: undefined,
        name: '',
        capacityPerMonth: undefined,
        capacityPerYear: undefined,
        pricePerUnit: undefined,
        productionValue: undefined,
        marketingArea: '',
        photos: []
      };
    };

    const marketingAreaLoading = ref(false);
    const hasMarketingArea = computed(() => !!formData.value?.marketingArea)

    const initForm = async () => {
      marketingAreaLoading.value = true
      await marketingAreaStore.getManyMarketingArea()
      marketingAreaLoading.value = false
    }

    watch(() => isEdit, v => {
      const isEditAndHasMarketing = unref(v) && unref(hasMarketingArea)
      // @ts-ignore
      if (isEditAndHasMarketing) formData.value.marketingArea = formData.value.marketingArea.name
    }, { deep: true })

    onMounted(async () => await initForm())

    return {
      headers,
      isLoading,
      showFormDialog,
      formData,
      reRenderCount,
      onSubmit,
      showDeleteDialog,
      showDetailDialog,
      clickDelete,
      clickDetail,
      onDelete,
      updateProductionPerYear,
      useNumberToCurrency,
      updateProductionValue,
      resetFormData,
      clickEdit,
      isEdit,
      marketingAreaStore,
      marketingAreaLoading
    };
  }
});
