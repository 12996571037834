










































import KButtonAction from '@/@core/components/button/KButtonAction.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KHeaderBackButton from '@/@core/components/page/KHeaderBackButton.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { IndustryPermissionForm } from '../../types';
import CommonForm from '../../ui/CommonForm.vue';
import { useIndustryStore as industryStore } from '../../services/store';
import router from '@/router';
import { useInitIndustryForm } from '../../helpers/initIndustryForm';
import ProductForm from '../../ui/ProductForm.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import KBreadcrumbs from '@/@core/components/navigation/KBreadcrumbs.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KHeaderBackButton,
    KHamburgerMenu,
    KText,
    KPageBody,
    KWrapper,
    CommonForm,
    KTextInput,
    KButtonAction,
    ProductForm,
    KBreadcrumbs
  },
  name: 'IndustryPermissionFormPage',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const reRenderCount = ref(0);
    const isEdit = computed(() => !!props.id);
    const isLoading = ref(false);
    const formData: Ref<IndustryPermissionForm> = ref({
      name: '',
      permissions: []
    });

    const store = industryStore();

    const dummyData = () => {
      const newDummy = {};

      formData.value = {
        ...formData.value,
        ...newDummy
      };

      reRenderCount.value++;
    };

    const onSubmit = async () => {
      router.push(`/app/industri/${props.id}`);
    };

    const init = async () => {
      const isEdit = props.id;

      if (isEdit) {
        const industry = await useInitIndustryForm(props.id);
        if (industry) {
          formData.value = {
            ...formData.value,
            ...industry
          };
        }

        reRenderCount.value++;
      }
    };

    onMounted(async () => {
      await init();
    });

    return {
      formData,
      dummyData,
      isLoading,
      onSubmit,
      reRenderCount,
      isEdit,
      init,
      isMobile
    };
  }
});
